.set-background-image {
  background-image: url('../brand/NewtonInsightsBranding/NIBackground.png');
  background-size: cover; /* This will make the image cover the entire container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center center;

  /* Add other background image styling properties as needed */
}

.main-header {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* Medium font weight */
  font-size: 34px;
  margin-bottom: 10px;
  color: black;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.sign-in-text {
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  color: #8898a7;
  text-align: center;
  padding: 0px 15px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.image-container img {
  max-width: 65%;
  height: auto;
  margin: 1.5rem 0px;
}

.form-content {
  padding: 0px;
}

.input-group {
  padding: 0px 30px;
}

.input {
  padding-left: 20px;
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(81, 78, 78, 0.18) !important;
  transition: all 0.3s ease;
  font-size: 10px;
}

.input:focus {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(74, 166, 237, 0.5) !important;
  box-shadow: 0 0 0 2px rgba(74, 166, 237, 0.1);
}

.flexContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  padding: 0 30px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  margin: 1rem 0px 0rem 0px;
  padding: 0px;
}

.custom-button {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 84%;
  margin-top: 5px;
}

.custom-button:hover {
  background: linear-gradient(180deg, #97a4e1a8, #007AFF);
  transform: scale(1.02);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.25);
}

.custom-button:focus {
  outline: none;
}

.register-button {
  background-color: #14eb0d;
  font-family: 'Bio Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase; /* Capitalize the text */
  border: none;
  border-radius: 10px; /* Adjust the value to control the roundness */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  height: fit-content;
  width: 150px;
}

.password-toggle {
  cursor: pointer;
}

.company-name {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* Medium font weight */
  font-size: 24px;
  color: black;
  text-align: center;
  margin-top: 60px;
  font-style: italic;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.register-link {
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  color: #8898a7;
  font-style: italic;
  border: none;
  margin-right: 28px;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password {
  font-family: 'Bio Sans', sans-serif;
  color: #8898a7;
  font-style: italic;
  border: none;
  margin-left: 28px;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password-button {
  color: #8898a7;
  border: none;
  background-color: transparent;
}

.glass-container {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem !important;
}

.input-group-text {
  background: rgba(215, 215, 215, 0.25) !important;
  border: 1px solid rgba(81, 78, 78, 0.18) !important;
  color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 1200px) and (max-height: 1100px) and (orientation: landscape) {
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    width: 250px;
    margin: 2rem 0px;
  }

  .form-content {
    padding: 0px;
  }
}

@media (max-width: 1200px) and (max-height: 1100px) {
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flexContainer {
    font-size: 0.8rem;
    padding: 0px 30px;
  }

  .button-container {
    padding: 0 !important;
  }

  .custom-button {
    width: 85% !important;
  }

  .custom-button {
    font-size: 0.8rem;
    padding: 8px 10px;
    margin: 0px 30px;
  }

  .main-header {
    font-size: 1.5rem;
  }

  .input-group {
    padding: 0px 30px;
  }

  .forgot-password {
    font-size: 0.8rem;
  }

  .register-link {
    font-size: 0.8rem;
  }

  .company-name {
    font-size: 1rem;
  }

  .form-content {
    padding: 0px;
  }

  .glass-container {
    max-width: 450px;
    padding: 1.5rem !important;
  }

  .form-content {
    padding: 0px 1.5rem;
  }
}

@media (max-width: 714px) {
  .form-content {
    padding: 0px 3rem;
  }

  .button-container {
    padding: 0px !important;
    margin-left: 0px;
  }

  .custom-button {
    margin-left: 2rem !important;
  }

  /* .forgot-password {
    margin-left: 30px;
  } */

  .glass-container {
    max-width: 400px;
    padding: 1rem !important;
  }

  .form-content {
    padding: 0px 1rem;
  }
}
