/* Root Variables */
:root {
  --glass-bg: rgba(255, 255, 255, 0.65);
  --glass-border: rgba(255, 255, 255, 0.25);
  --glass-shadow: rgba(104, 139, 255, 0.1);
  --primary-color: #007AFF;
}

/* Container Styles */
.input-parameters-container {
  background: linear-gradient(135deg, #f8faff 0%, #eef2ff 100%);
  min-height: 100%;
}

/* Header Section */
.header-section {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
}

.customize-link {
  display: flex;
  align-items: center;
}

.edit-fields-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: var(--glass-bg);
  backdrop-filter: blur(8px);
  border: 1px solid var(--glass-border);
}

.edit-fields-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.edit-icon {
  font-size: 1rem;
}

/* Main Content */
.main-content {
  background: var(--glass-bg);
  backdrop-filter: blur(16px);
  border: 1px solid var(--glass-border);
  border-radius: 20px;
  box-shadow: 0 4px 24px var(--glass-shadow);
  margin: 0 auto;
  padding: 1rem;
}

/* Input Header */
.input-header {
  text-align: center;
  margin-bottom: 2rem;
}

.page-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #0f172a;
  margin-bottom: 0.5rem;
  background: linear-gradient(90deg, var(--primary-color), #a5b4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-description {
  color: #64748b;
  font-size: 1rem;
}

/* Fields Grid */
.fields-grid {
  display: grid;
  gap: 1rem;
  padding: 1rem;
}

/* Form Fields */
.input-width {
  width: 80%;
}

.form-label {
  font-weight: 500;
  color: #334155;
  margin-bottom: 0.5rem;
}

.form-control {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(130, 146, 250, 0.3);
  border-radius: 8px;
  padding: 0.5rem;
  transition: all 0.2s ease;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
}

/* Error Messages */
.error {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Form Actions */
.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.submit-button {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8) !important;
  color: white !important;
  padding: 0.75rem 2rem !important;
  border-radius: 12px !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  text-transform: none !important;
}

.submit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.spinner {
  width: 1.5rem;
  height: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .fields-grid {
    grid-template-columns: 1fr;
  }

  .main-content {
    padding: 1rem;
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .fields-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .main-content {
    max-width: 100%;
  }
}

@media (min-width: 1025px) {
  .fields-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .main-content {
    max-width: 1200px;
  }
}
